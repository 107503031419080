import React, { useState, useEffect, useContext } from 'react';

// - react-bootstrap
import { Container, Col, Row } from 'react-bootstrap';

// - data
// import disciplines from 'assets/disciplines';
// import news from 'assets/news';

// - styles
import { Section } from '../homeStyles';
import {
  NewsTitle,
  ImageHolder,
  Divider,
  NewsButton,
  FilterButton,
} from '../styles/newsStyles';

// - tradução
import { LanguageContext } from 'containers/Language';

const origin = process.env.REACT_APP_BASE_URL;

function News({ Text }) {
  const [filter, setFilter] = useState('All');

  // const [newsList, setNewsList] = useState([]);

  const [filteredNews, setFilteredNews] = useState([]);

  
const [disciplines, setDisciplines] = useState([]);
  const { userLanguage } = useContext(LanguageContext);

 /*  useEffect(() => {
    fetch(`${origin}/api/disciplines`)
      .then(async (response) => {
        const json = await response.json();
        setDisciplines(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []); */

  useEffect(() => {
    if (filter === 'All') {
      fetch(`${origin}/api/news?page=1&perPage=6`)
        .then(async (response) => {
          const json = await response.json();
          setFilteredNews(json);
        })
        .catch((error) => {
          console.log('erro', error);
        });
    } else {
      fetch(`${origin}/api/disciplines/${filter._id}/news?page=1&perPage=6`)
        .then(async (response) => {
          const json = await response.json();
          //console.log(typeof json, filter)
          setFilteredNews(json);
        })
        .catch((error) => {
          console.log('erro', error);
        });
    }
  }, [filter]);

  /* const filteredNews = useMemo(() => newsList.filter((news) => (
    filter === 'All'
      ? news
      : news.discipline === filter
  )), [news, filter]); */

  /* useEffect(() => {
    setNewsList(news);
    setFilter('All');
  }, []);

  useEffect(() => {
    setNewsList([]);

    const filtered = news.map((n) => ({ ...n, filtered: n.category.includes(filter) }));

    setNewsList(filtered);
  }, [filter]);
 */

  return (
    <Section>
      <Container>
        {/* <Row>
          <div className="labels">
            <FilterButton
              active={filter === 'All'}
              onClick={() => setFilter('All')}
            >
              All
            </FilterButton>
            {disciplines.map((discipline) => (
              <FilterButton
                key={discipline._id}
                active={filter === discipline}
                onClick={() => setFilter(discipline)}
              >
                {userLanguage === 'es'
                  ? discipline.spanish.title
                  : discipline.english.title}
              </FilterButton>
            ))}
          </div>
        </Row> */}

        {filteredNews.length !== 0 ? (
          <Row>
            {filteredNews.map((newsItem) => {
              /* let newsItemDiscipline;

                  if (filter === 'All') {
                    newsItemDiscipline = disciplines.find((discipline) => (
                      discipline._id == newsItem.discipline
                    ));
                  } */

                  return (
                    <Col
                      lg="4"
                      className="py-2 d-flex flex-column justify-content-between"
                      key={newsItem._id}
                    >
                      <ImageHolder to={`/news/${newsItem._id}`}>
                          <img
                            src={`${origin}${newsItem.imagePath}`}
                            className="img-responsive"
                            alt=""
                            style={{ height: '270px', width: '100%' }}
                          />
                          <div className="caption">
                            <h4>
                              {userLanguage === 'es'
                                ? newsItem.spanish.title
                                : newsItem.english.title}
                            </h4>
                          </div>
                          
                          <div className="category">
                            {filter === 'All'
                              ? userLanguage === 'es'
                                ? 'COPAC ONLINE'
                                : 'COPAC ONLINE'
                              : userLanguage === 'es'
                              ? filter.spanish.title
                              : filter.english.title}
                          </div>
                        </ImageHolder>
                    </Col>
                  );

              /* return (
                <Col
                  lg="4"
                  className="py-2 d-flex flex-column justify-content-between"
                  key={newsItem._id}
                >
                  <div className="mb-4">
                    <ImageHolder>
                      <img
                        src={`${origin}${newsItem.imagePath}`}
                        className="img-responsive"
                        alt=""
                        style={{ height: '200px', width: '100%' }}
                      />
                      <div className="caption">
                        <h4>
                          {filter === 'All'
                            ? userLanguage === 'es'
                              ? 'Todas las disciplinas'
                              : 'All disciplines'
                            : userLanguage === 'es'
                            ? filter.spanish.title
                            : filter.english.title}
                        </h4>
                      </div>
                    </ImageHolder>
                    <NewsTitle>
                      {userLanguage === 'es'
                        ? newsItem.spanish.title
                        : newsItem.english.title}
                    </NewsTitle>
                    <Divider />
                    <p>
                      {userLanguage === 'es'
                        ? newsItem.spanish.subheadline
                        : newsItem.english.subheadline}
                    </p>
                  </div>
                  <NewsButton href={`/news/${newsItem._id}`}>
                    <Text content="readMore" />
                  </NewsButton>
                </Col>
              ); */
            })}
          </Row>
        ) : (
          <Row>
            <div
              className="alert alert-warning text-center"
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                margin: '32px  0 0',
                width: '100%',
              }}
            >
              <Text content="noRecordsFound" />
            </div>
          </Row>
        )}
      </Container>
    </Section>
  );

  /* return (
    <Section>
      <Container>
        <Row>
          <div className="labels">
            <FilterButton active={filter === 'All'} onClick={() => setFilter('All')}>All</FilterButton>
            {disciplines.map((discipline) => (
              <FilterButton
                active={filter === discipline.name}
                onClick={() => setFilter(discipline.name)}
              >
                {(
                  userLanguage === 'es'
                    ? discipline.spanish.title
                    : discipline.english.title
                )}
              </FilterButton>
            ))}
          </div>
        </Row>
        <Row>

          {newsList.map((news, i) => (news.filtered === true ? (
            <Col lg="4" className="py-4 d-flex flex-column justify-content-between" key={i}>
              <div className="mb-4">
                <ImageHolder>
                  <img src={news.image} className="img-responsive" alt={news.title} />
                  <div className="caption">
                    <h4>{news.category[1]}</h4>
                  </div>
                </ImageHolder>
                <NewsTitle>{news.title}</NewsTitle>
                <Divider />
                <p>
                  {news.content}
                </p>
              </div>
              <NewsButton href="#news"><Text content="readMore" /></NewsButton>
            </Col>
          ) : ''))}
        </Row>
      </Container>
    </Section>
  ); */
}

export default News;
