import React, { useState, useEffect, useContext } from 'react';

import PageHeader from 'components/PageHeader';

// - sections

// Styles
import {
  DocumentContainer,
  NewsButton,
  NewsButtonContainer,

  Form, 
  Input, 
  elect, 
  Arrow, 
  Button,
} from './styles.js';


import Cover from 'assets/img/documents.png';

// - react-bootstrap
import { Container, Col, Row } from 'react-bootstrap';

// - tradução
import { LanguageContext } from 'containers/Language';
// - Texto traduzido
import { Text } from 'containers/Language';

// const iconPDF = 'assets/img/iconPDF';
import iconPDF from 'assets/img/iconPDF.png';

// - Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';

const origin = process.env.REACT_APP_BASE_URL;

function Documents() {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    fetch(`${origin}/api/documents`)
      .then(async (response) => {
        const json = await response.json();
        setDocuments(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []);
  

  const { userLanguage } = useContext(LanguageContext);

  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'About Us',
      path: '/about',
    },
    {
      title: 'Events',
    },
  ];

  //const [inputValue, setInputValue] = useState(''); // Armazena a entrada do usuário
  const [searchTerm, setSearchTerm] = useState(''); // Atualizado ao clicar em "search"

  // Filtra os documentos com base no termo de busca
  const filteredDocuments = (
      documents.length !== 0 
        ? documents.filter((doc) => doc.name.toLowerCase().includes(searchTerm.toLowerCase()))
        : []
  );

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <>
      <PageHeader
        bannerTitle="documents"
        pageTitle="documents"
        breadcrumbs={breadcrumbs}
        //img={Cover}
        page="documents"
      />

      {documents.length !== 0 ? (
        <Container>
          <Form /* onSubmit={handleSubmit} */>
            <Row className="justify-content-around">
              {/* <Col lg="4" md="6" sm="12" style={{ position: 'relative' }}>
                <label htmlFor="country">Country</label>
                <Select id="country" className="d-block">
                  {federations.map((federation) => (
                    <option key={federation.ioc} value={federation.country}>{federation.country}</option>
                  ))}
                </Select>
                <Arrow>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    size="lg"
                    color="white"
                  />
                </Arrow>

              </Col> */}
              {/* <Col lg="3" md="6" sm="12">
                <label htmlFor="name">Name</label>
                <Input id="name" type="text" placeholder="Type here the name" className="d-block" />
              </Col> */}
              <Col /* lg="3" md="6" sm="12" */>
                {/* <label htmlFor="ioc">IOC Code</label> */}
                <Input 
                  id="ioc" 
                  type="text" 
                  placeholder={userLanguage === 'es'
                    ? 'Busca el documento aquí'
                    : 'Search for the document here'
                  }
                  className="d-block"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Col>
              <Col lg="2" md="6" sm="12" className="d-flex align-items-end justify-content-end">
                <Button /* type="submit" */ /* onClick={handleSubmit} */>
                  Search
                  <FontAwesomeIcon
                    icon={faSearch}
                    size="lg"
                    color="white"
                    style={{ marginLeft: 10 }}
                  />
                </Button>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col style={{ margin: '48px 0 54px' }}>
              {filteredDocuments.map((statute) => (
                <DocumentContainer
                  className="d-sm-flex align-items-center mb-2 mb-sm-0"
                  key={statute._id}
                >
                  <div style={{ width: '60px', marginRight: '16px' }}>
                    <img src={iconPDF} alt="" className="img-responsive" />
                  </div>
                  <h5 style={{ marginTop: '16px', flex: 1 }}>{statute.name}</h5>
                  <NewsButtonContainer>
                    <NewsButton
                      href={`${origin}${statute.filePath}`}
                      target="_blank"
                    >
                      Download
                    </NewsButton>
                  </NewsButtonContainer>
                </DocumentContainer>
              ))}
            </Col>
          </Row>
        </Container>
      ) : (
        <div
          className="alert alert-warning text-center"
          style={{ fontSize: '16px', fontWeight: 'bold', margin: '20px' }}
        >
          <Text content="noRecordsFound" />
        </div>
      )}
    </>
  );
}

export default Documents;
