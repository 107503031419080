import React from 'react';

// - react-bootstrap
import {
  Container, Col, Row,
} from 'react-bootstrap';

import PageHeader from 'components/PageHeader';

// - sections
import ContactInfo from './sections/ContactInfo';
import ContactForm from './sections/ContactForm';

import Cover from 'assets/img/contact.png'

function Contact() {
  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'Contact',
    },
  ];

  return (
    <>
      <PageHeader 
        bannerTitle="Contact" 
        pageTitle="Contact" 
        breadcrumbs={breadcrumbs} 
        //img={Cover} 
        page="contact"
        />
      <Container>
        <Row style={{ padding: '50px 0' }}>
          <Col lg="6" sm="12">
            <ContactInfo />
          </Col>
          <Col lg="6" sm="12">
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Contact;
