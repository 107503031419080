import React from 'react';

// -- FontAwesome
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// -- styled-components do Footer
import { ContactInfo } from '../styles';

export default function Contact({ Text }) {
  return (
    <ContactInfo>
      <li>
        {/* PAN AMERICAN CANOE FEDERATION CONFEDERACIÓN PANAMERICANA DE CANOAS */}
        Pan American Canoe Federation <br />
        Confederación Panamericana de Canoas
      </li>

      <li>
        <FontAwesomeIcon icon={faEnvelope} />
        Email: copac@copaconline.com
      </li>
    </ContactInfo>
  );
}
