import React, { useState, useEffect, useContext } from 'react';

import LanguageSelector from 'components/LanguageSelector';

// -- styled-components do Footer
import {
  FooterTitle,
  TitleUnderline,
  SocialList,
} from '../styles';

// - tradução
import { LanguageContext } from 'containers/Language';

const origin = process.env.REACT_APP_BASE_URL;

export default function Disciplines({ Text }) {
  const [disciplines, setDisciplines] = useState([]);

  const { userLanguage } = useContext(LanguageContext);

  useEffect(() => {
    fetch(`${origin}/api/disciplines`)
      .then(async (response) => {
        const json = await response.json();
        setDisciplines(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });

  }, []);

  return (
    <>
      <FooterTitle><Text content="disciplines" /></FooterTitle>
      <TitleUnderline />
      <div className="clearfix" />

      <SocialList>
        {disciplines.map((discipline) => (
          <li
            key={discipline._id}
          >
            <a href={`/disciplines/${discipline._id}`}>
              {userLanguage === 'es'
                ? discipline.spanish.title
                : discipline.english.title}
            </a>
          </li>
        ))}
      </SocialList>

      {/* <LinkList>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            About copac
          </a>
        </LinkItem>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            Discplines
          </a>
        </LinkItem>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            News
          </a>
        </LinkItem>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            Events
          </a>
        </LinkItem>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            Contact
          </a>
        </LinkItem>
      </LinkList> */}
    </>
  );
}
