import React, { useState, useEffect, useContext, useRef } from 'react';

import styled from 'styled-components';

// import Carousel from 'react-bootstrap/Carousel';
import Slider from 'react-slick';

// - styles
import { BannerContainer } from '../styles/bannerStyles';

// - react-bootstrap
import { Container, Col, Row } from 'react-bootstrap';

// -- CSS de apoio
import 'assets/styles/carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// -- imagens para os banners
// import banner from 'assets/img/sliders/masterslider/banner001.png';

// - tradução
import { LanguageContext } from 'containers/Language';


const origin = process.env.REACT_APP_BASE_URL;


function NextArrow(props) {
  const { onClick } = props;
  return (
    <button className="carousel-control-prev" onClick={onClick}>
      <span aria-hidden="true" className="carousel-control-prev-icon" />
      <span className="sr-only">Previous</span>
    </button>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button className="carousel-control-next" onClick={onClick}>
      <span aria-hidden="true" className="carousel-control-next-icon" />
      <span className="sr-only">Next</span>
    </button>
  );
}


export const NewsTitle = styled.h3`
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
`;

export const NewsContent = styled.div`
  background-color: #006cb1;
  color: #fff;

  padding: 48px;// 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 16px;

  height: 600px;

  h1 {
    font-weight: 500;
    text-shadow: none !important;
    color: #fff;

    display: -webkit-box;
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 8;       
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Media query para telas menores que 720px
  @media (max-width: 720px) {
    padding: 32px 20px;
    height: 320px;

    h1 {
      font-size: 24px;
      line-height: 120%;
      margin: 0;
    }
  }
`;

export default function BannerCarousel({ Text }) {
  const [slide, setSlide] = useState({
    oldSlide: 0,
    activeSlide: 0,
  });

  const [isMobile, setIsMobile] = useState(false);

  const settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 380,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow:  isMobile === true ? <PrevArrow /> : null,
    beforeChange: (current, next) =>
      setSlide({ oldSlide: current, activeSlide: next }),
  };

  const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 300,
    pauseOnHover: true,
    nextArrow: null,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) =>
      setSlide({ oldSlide: current, activeSlide: next }),
  };

  const animationTitle = {
    WebkitAnimationDelay: '.7s',
    animationDelay: '.7s',
    //textShadow: '0 0 6px rgb(54 54 54 / 60%)',
  };

  const animationText = {
    WebkitAnimationDelay: '1.4s',
    animationDelay: '1.4s'
  }

  const animationButton = {
    //WebkitAnimationDelay: '2.1s',
    //animationDelay: '2.1s',
    WebkitAnimationDelay: '0.9s',
    animationDelay: '0.9s',
  };

  const { userLanguage } = useContext(LanguageContext);
  const firstRender = useRef(123);

  const [newsList, setNewsList] = useState([]);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    fetch(`${origin}/api/news?page=1&perPage=4`)
      .then(async (response) => {
        const json = await response.json();
        setNewsList(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, [isMobile]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 720) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, []);

  if (isMobile) {
    return (
      <div className="carousel slide">
        <Slider 
          className="carousel-inner" 
          asNavFor={nav2}
          ref={slider => (sliderRef1 = slider)}
          {...settings1}
        >
          {newsList.map((newsItem) => (
            <div
              className={`carousel-item ${firstRender && 'active'}`}
              key={newsItem._id}
            >
              <a
                href={`/news/${newsItem._id}`}
              >
                <img
                  className="d-block w-100 height"
                  //src={banner}
                  src={`${origin}${newsItem.imagePath}`}
                  alt="First slide"

                  style={{height: '200px'}}
                />
              </a>
            </div>
          ))}
        </Slider>

        <Slider 
          className="carousel-inner " 
          asNavFor={nav1}
          ref={slider => (sliderRef2 = slider)}
          {...settings2}
        >
          {newsList.map((newsItem) => (
            <div
              className={`carousel-item ${firstRender && 'active'}`}
              style={{height: 'auto'}}
              key={newsItem._id}
            >
              <a href={`/news/${newsItem._id}`}>
                <NewsContent >
                <h1
                  //className={`text1 ${firstRender && 'slide-right'}`}
                  style={animationTitle}
                >
                  {userLanguage === 'es'
                    ? newsItem.spanish.title
                    : newsItem.english.title}
                </h1>
                <p>
                  {userLanguage === 'es'
                    ? newsItem.spanish.subheadline
                    : newsItem.english.subheadline}
                </p>
                <a
                  href={`/news/${newsItem._id}`}
                  className={`button ${firstRender && 'slide-right'}`}
                  style={animationButton}
                >
                  <Text content="readMore" />
                </a>
                </NewsContent>
              </a>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <div className="carousel slide">
        <Row className='g-0'>
          <Col md={8} xs={12} className="p-0">
            <Slider 
              className="carousel-inner " 
              asNavFor={nav2}
              ref={slider => (sliderRef1 = slider)}
              {...settings1}
            >
              {newsList.map((newsItem) => (
                <div
                  className={`carousel-item ${firstRender && 'active'}`}
                  key={newsItem._id}
                >
                  <a
                    href={`/news/${newsItem._id}`}
                  >
                    <img
                      className="d-block w-100 height"
                      //src={banner}
                      src={`${origin}${newsItem.imagePath}`}
                      alt="First slide"

                      style={{height: '600px'}}
                    />
                  </a>
                </div>
              ))}
            </Slider>
          </Col>
          <Col md={4} xs={12} className="p-0">
            <Slider 
              className="carousel-inner " 
              asNavFor={nav1}
              ref={slider => (sliderRef2 = slider)}
              {...settings2}
            >
              {newsList.map((newsItem) => (
                <div
                 className={`carousel-item ${firstRender && 'active'}`}
                 style={{height: 'auto'}}
                  key={newsItem._id}
                >
                  <a href={`/news/${newsItem._id}`}>
                    <NewsContent >
                    <h1
                      //className={`text1 ${firstRender && 'slide-right'}`}
                      style={animationTitle}
                    >
                      {userLanguage === 'es'
                        ? newsItem.spanish.title
                        : newsItem.english.title}
                    </h1>
                    <p>
                      {userLanguage === 'es'
                        ? newsItem.spanish.subheadline
                        : newsItem.english.subheadline}
                    </p>
                    <a
                      href={`/news/${newsItem._id}`}
                      className={`button ${firstRender && 'slide-right'}`}
                      style={animationButton}
                    >
                      <Text content="readMore" />
                    </a>
                    </NewsContent>
                  </a>
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
    </div>
  );

  /* return (
    <div className="carousel slide">
      <Slider className="carousel-inner " {...settings}>
        {newsList.map((newsItem) => (
          <div
            className={`carousel-item ${firstRender && 'active'}`}
            key={newsItem._id}
          >
            <img
              className="d-block w-100 height"
              //src={banner}
              src={`${origin}${newsItem.imagePath}`}
              alt="First slide"
            />
            <div className="carousel-caption">
              <NewsTitle
                className={`text1 ${firstRender && 'slide-right'}`}
                style={animationTitle}
              >
                {userLanguage === 'es'
                  ? newsItem.spanish.title
                  : newsItem.english.title}
              </NewsTitle>
              <a
                href={`/news/${newsItem._id}`}
                className={`button ${firstRender && 'slide-right'}`}
                style={animationButton}
              >
                <Text content="readMore" />
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  ); */
}
