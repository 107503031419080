import React, { useState, useEffect } from 'react';

import PageHeader from 'components/PageHeader';

import Cover from 'assets/img/statutes.png';

// - sections

// Styles
import {
  DocumentContainer,
  NewsButton,
  NewsButtonContainer,
} from './styles.js';

// - react-bootstrap
import { Container, Col, Row } from 'react-bootstrap';

// - tradução
// import { LanguageContext } from 'containers/Language';
// - Texto traduzido
import { Text } from 'containers/Language';

// const iconPDF = 'assets/img/iconPDF';
import iconPDF from 'assets/img/iconPDF.png';

const origin = process.env.REACT_APP_BASE_URL;

function Statutes() {
  //const { userLanguage } = useContext(LanguageContext);

  const [statutes, setStatutes] = useState([]);

  useEffect(() => {
    fetch(`${origin}/api/statutes`)
      .then(async (response) => {
        const json = await response.json();
        setStatutes(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []);

  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'About Us',
      path: '/about',
    },
    {
      title: 'Events',
    },
  ];

  return (
    <>
      <PageHeader
        bannerTitle="statutes"
        pageTitle="statutes"
        breadcrumbs={breadcrumbs}
        //img={Cover}
        page="statutes"
      />

      {statutes.length !== 0 ? (
        <Container>
          <Row>
            <Col style={{ margin: '48px 0 54px' }}>
              {statutes.map((statute) => (
                <DocumentContainer
                  className="d-sm-flex align-items-center mb-2 mb-sm-0"
                  key={statute._id}
                >
                  <div style={{ width: '60px', marginRight: '16px' }}>
                    <img src={iconPDF} alt="" className="img-responsive" />
                  </div>
                  <h5 style={{ marginTop: '16px', flex: 1 }}>{statute.name}</h5>
                  <NewsButtonContainer>
                    <NewsButton
                      href={`${origin}${statute.filePath}`}
                      target="_blank"
                    >
                      Download
                    </NewsButton>
                  </NewsButtonContainer>
                </DocumentContainer>
              ))}
            </Col>
          </Row>
        </Container>
      ) : (
        <div
          className="alert alert-warning text-center"
          style={{ fontSize: '16px', fontWeight: 'bold', margin: '20px' }}
        >
          <Text content="noRecordsFound" />
        </div>
      )}
    </>
  );
}

export default Statutes;
