import React, { useEffect, useState } from 'react';

// - react-bootstrap
import { Container /* , Col, Row, */ } from 'react-bootstrap';

import PageHeader from 'components/PageHeader';

// - images
import Cover from 'assets/img/committees.png';

// - styles
import { TeamHolder, ImageHolder, Name, CommitteesContainer } from './styles';

const origin = process.env.REACT_APP_BASE_URL;

function Committees() {
  const [committees, setCommittees] = useState([]);

  useEffect(() => {
    fetch(`${origin}/api/committees`)
      .then(async (response) => {
        const json = await response.json();
        setCommittees(json);
        console.log(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []);

  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'About Us',
      path: '/about',
    },
    {
      title: 'Committees',
    },
  ];

  return (
    <>
      <PageHeader
        bannerTitle="committees"
        pageTitle="committees"
        breadcrumbs={breadcrumbs}
        //img={Cover}
        page="committees"
      />
      <Container>
        <div className="my-4 py-4">
          <CommitteesContainer>
            {committees.map((director) => (
              <TeamHolder key={director._id}>
                <ImageHolder>
                  <img
                    src={`${origin}${director.imagePath}`}
                    alt=""
                    className="img-responsive"
                  />
                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">{director.name}</h5>
                <Name>{director.position.english}</Name>
              </TeamHolder>
            ))}
          </CommitteesContainer>
        </div>
      </Container>
    </>
  );

  /* return (
    <>
      <PageHeader bannerTitle="Committees" pageTitle="Committees" breadcrumbs={breadcrumbs} />
      <Container>
        <div className="my-4 py-4 d-inline-block">
          <Row>
            <Col lg={3} md={6} className="py-4">
              <TeamHolder>
                <ImageHolder>
                  <div className="hover-box">
                    <p className="text-white"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum.</p>
                  </div>
                  <img src="http://placehold.it/400x450" alt="Charles Luckman (USA) " className="img-responsive" />

                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">Charles Luckman (USA) </h5>
                <Name>Canoe Sprint </Name>

              </TeamHolder>
            </Col>

            <Col lg={3} md={6} className="py-4">
              <TeamHolder>
                <ImageHolder>
                  <div className="hover-box">
                    <p className="text-white"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum.</p>
                  </div>
                  <img src="http://placehold.it/400x450" alt="Alex Ian Olguin Henriquez (CHI)" className="img-responsive" />

                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">Alex Ian Olguin Henriquez (CHI)</h5>
                <Name>Canoe Slalom </Name>

              </TeamHolder>
            </Col>

            <Col lg={3} md={6} className="py-4">
              <TeamHolder>
                <ImageHolder>
                  <div className="hover-box">
                    <p className="text-white"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum.</p>
                  </div>
                  <img src="http://placehold.it/400x450" alt="Miguel Angel Arce (ARG)" className="img-responsive" />

                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">Miguel Angel Arce (ARG)</h5>
                <Name>Canoe Polo</Name>

              </TeamHolder>
            </Col>

            <Col lg={3} md={6} className="py-4">
              <TeamHolder>
                <ImageHolder>
                  <div className="hover-box">
                    <p className="text-white"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum.</p>
                  </div>
                  <img src="http://placehold.it/400x450" alt="Carlos Muslow Cavada (CHI) " className="img-responsive" />

                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">Carlos Muslow Cavada (CHI) )</h5>
                <Name>Marathon</Name>

              </TeamHolder>
            </Col>

          </Row>
          <Row>
            <Col lg={3} md={6} className="py-4">
              <TeamHolder>
                <ImageHolder>
                  <div className="hover-box">
                    <p className="text-white"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum.</p>
                  </div>
                  <img src="http://placehold.it/400x450" alt=" Jefferson Camargo Sestaro (BRA)" className="img-responsive" />

                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1"> Jefferson Camargo Sestaro (BRA)</h5>
                <Name>Canoe Ocean Racing</Name>

              </TeamHolder>
            </Col>

            <Col lg={3} md={6} className="py-4">
              <TeamHolder>
                <ImageHolder>
                  <div className="hover-box">
                    <p className="text-white"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum.</p>
                  </div>
                  <img src="http://placehold.it/400x450" alt="Andrea Waters (CAN)" className="img-responsive" />

                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">Andrea Waters (CAN)</h5>
                <Name>Paracanoe</Name>

              </TeamHolder>
            </Col>

            <Col lg={3} md={6} className="py-4">
              <TeamHolder>
                <ImageHolder>
                  <div className="hover-box">
                    <p className="text-white"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum.</p>
                  </div>
                  <img src="http://placehold.it/400x450" alt="William Jurjus Yousef (BRA)" className="img-responsive" />

                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">William Jurjus Yousef (BRA)</h5>
                <Name>Medical</Name>

              </TeamHolder>
            </Col>

          </Row>
        </div>
      </Container>
    </>
  ); */
}

export default Committees;
