/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';

// - react-bootstrap
import {
  Container, Col, Row,
} from 'react-bootstrap';

import PageHeader from 'components/PageHeader';

// // - images
// import brasil from 'assets/img/federations/port/bra.jpg';
// import chile from 'assets/img/federations/port/chi.jpg';
// import mexico from 'assets/img/federations/port/mex.jpg';
// import paraguai from 'assets/img/federations/port/par.jpg';
// import peru from 'assets/img/federations/port/per.jpg';
// import uruguai from 'assets/img/federations/port/uru.jpg';

// - styles
import {
  Title, Underline,/*  SubTitle, */
} from './styles';

import Cover from 'assets/img/nationalfederations.png'

// - sections
import FederationsTable from './sections/FederationsTable';

function NationalFederations() {
  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'About Us',
      path: '/about',
    },
    {
      title: 'National Federations',
    },
  ];

  return (
    <>
      <PageHeader 
        bannerTitle="nationalFederations"
        pageTitle="nationalFederations" 
        breadcrumbs={breadcrumbs} 
        img={Cover} 
        page="national-federations"
      />
      <Container>
        <Row style={{ paddingTop: 80 }}>
          <Col sm={12} className="text-center">
            <Title>National Federations</Title>
            <Underline />
            {/* <SubTitle>Lorem ipsum dolor sit amet consectetuer adipiscing elit.</SubTitle> */}
          </Col>
          <div className="clearfix" />
        </Row>
        <FederationsTable />
      </Container>
      {/* <Container fluid>
        <Row className="pb-4 mb-4">
          <div className="col-sm-6 col-md-4 col-lg-5ths pb-4">
            <OverlayWrapper>
              <a target="_blank" rel="noopener noreferrer" href="http://www.canoagem.org.br/">
                <img src={brasil} alt="" className="img-responsive" />
                <Overlay>
                  <h3>Confederação Brasileira de Canoagem </h3>
                  <p>Lorem ipsum dolor sit amet consectetuer adipiscing elit.</p>
                </Overlay>
              </a>
            </OverlayWrapper>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-5ths pb-4">
            <OverlayWrapper>
              <a target="_blank" rel="noopener noreferrer" href="#">
                <img src={chile} alt="" className="img-responsive" />
                <Overlay>
                  <h3>Federacion Chilena de Canotaje </h3>
                  <p>Lorem ipsum dolor sit amet consectetuer adipiscing elit.</p>
                </Overlay>
              </a>
            </OverlayWrapper>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-5ths pb-4">
            <OverlayWrapper>
              <a target="_blank" rel="noopener noreferrer" href="#">
                <img src={mexico} alt="" className="img-responsive" />
                <Overlay>
                  <h3>Federación Mexicana de Canotaje, A.C. </h3>
                  <p>Lorem ipsum dolor sit amet consectetuer adipiscing elit.</p>
                </Overlay>
              </a>
            </OverlayWrapper>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-5ths pb-4">
            <OverlayWrapper>
              <a target="_blank" rel="noopener noreferrer" href="#">
                <img src={paraguai} alt="" className="img-responsive" />
                <Overlay>
                  <h3>Federacion Paraguaya de Canotaje</h3>
                  <p>Lorem ipsum dolor sit amet consectetuer adipiscing elit.</p>
                </Overlay>
              </a>
            </OverlayWrapper>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-5ths pb-4">
            <OverlayWrapper>
              <a target="_blank" rel="noopener noreferrer" href="#">
                <img src={peru} alt="" className="img-responsive" />
                <Overlay>
                  <h3>Federación Deportiva Nacional de Canotaje</h3>
                  <p>Lorem ipsum dolor sit amet consectetuer adipiscing elit.</p>
                </Overlay>
              </a>
            </OverlayWrapper>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-5ths pb-4">
            <OverlayWrapper>
              <a target="_blank" rel="noopener noreferrer" href="#">
                <img src={uruguai} alt="" className="img-responsive" />
                <Overlay>
                  <h3>Federación Uruguaya de Canotaje</h3>
                  <p>Lorem ipsum dolor sit amet consectetuer adipiscing elit.</p>
                </Overlay>
              </a>
            </OverlayWrapper>
          </div>

        </Row>
      </Container> */}
    </>
  );
}

export default NationalFederations;
