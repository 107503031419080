import React from 'react';

import PageHeader from 'components/PageHeader';

// - sections
import NewsList from './sections/NewsListTest';
// import Federations from './sections/Federations';

import Cover from 'assets/img/news.png'

function News() {
  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'News',
    },
  ];

  return (
    <>
      <PageHeader 
        bannerTitle="Copac News" 
        bannerSubtitle="Latest news about canoe" 
        pageTitle="news" 
        breadcrumbs={breadcrumbs} 
        //img={Cover} 
        page="news"
      />

      <NewsList />

      {/* <Federations /> */}
    </>
  );
}

export default News;
