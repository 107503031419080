import React, { useState, useEffect, useCallback } from 'react';

// - react-bootstrap
import { Container, Col, Row /* , Pagination */ } from 'react-bootstrap';

// -- Sections
import Contact from './sections/Contact';
import RecentNews from './sections/RecentNews';

import QuickLinks from './sections/QuickLinks';
//import Instagram from './sections/Instagram';

import AboutCopac from './sections/AboutCopac';
import Disciplines from './sections/Disciplines';
import Social from './sections/Social';

// - Texto traduzido
import { Text } from 'containers/Language';

// - styles
import {
  FooterContainer,
  Copyright,
  ScrollUpButton,
  FooterLogo,
  WrapperMargin,
} from './styles';

// -- Imagens
import Logo from 'assets/img/logo/white-logo.png';

/* const origin = 'http://localhost:5500'; */

function Footer() {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  }, [showScroll]);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  /* const [recentNews, setRecentNews] = useState([]); */

  useEffect(() => {
    // roda ao carregar o componente - componentDidMount()
    window.addEventListener('scroll', checkScrollTop);

    return () => {
      // roda depois que o componente foi removido - componentWillUnmount()
      window.addEventListener('scroll', () => checkScrollTop);
    };
  }, [checkScrollTop]);

  /*  useEffect(() => {
    fetch(`${origin}/api/news?page=1&perPage=2`)
      .then(async (response) => {
        const json = await response.json();
        setRecentNews(json)
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []);
 */
  return (
    <>
      <FooterContainer>
        <div className="clearfix" />

        {/* <SectionColor black> */}
        <Container className="container-pb">
          <Row>
            <Col xs={12} md={4} className="mb-5" style={{paddingRight: 48}} >
              <FooterLogo>
                <img src={Logo} alt="Logo" />
              </FooterLogo>

              <Contact Text={Text} />
            </Col>
            <Col xs={12} md={2} className="mb-5">
              <WrapperMargin>
                <QuickLinks Text={Text} />
              </WrapperMargin>
            </Col>

            <Col xs={12} md={2} className="mb-5">
              <WrapperMargin>
                <AboutCopac Text={Text} />
              </WrapperMargin>
            </Col>

            <Col xs={12} md={2} className="mb-5">
              <WrapperMargin>
                <Disciplines Text={Text} />
              </WrapperMargin>
            </Col>

            <Col xs={12} md={2} className="mb-5">
              <WrapperMargin>
                <Social Text={Text} />
              </WrapperMargin>
            </Col>
          </Row>

          {/* <div className="row">
            <div className="col-lg-3 clearfix">
              <FooterLogo>
                <img src={Logo} alt="Logo" />
              </FooterLogo>

              <Contact Text={Text} />
            </div>

            <div className="col-lg-3 clearfix">
              <WrapperMargin>
                <RecentNews Text={Text} />
              </WrapperMargin>
            </div>

            <div className="col-lg-3 clearfix">
              <WrapperMargin>
                <QuickLinks Text={Text} />
              </WrapperMargin>
            </div>

            <div className="col-lg-3 clearfix">
              <Instagram Text={Text} />
            </div>
          </div> */}
        </Container>
        {/* </SectionColor> */}
      </FooterContainer>

      <Copyright>
        Copyright © 2020 COPAC | By fredmosc.dev |{' '}
        <Text content="allRightReserved" />
      </Copyright>

      <div className="clearfix" />

      <ScrollUpButton onClick={handleScrollTop} />
    </>
  );
}

export default Footer;
