import React, { useEffect, useState, useContext } from 'react';

// - react-bootstrap
import { Container, Col, Row } from 'react-bootstrap';

// - components
import PageHeader from 'components/PageHeader';
import DisciplineNav from 'components/DisciplineNav';

// import disciplines from 'assets/disciplines';

// - images
import banner from 'assets/img/1920x600.png';
// import disciplineImg from 'assets/img/canoe_sprint_small.jpg';

// - styles
import { DisciplineTitle, Divider, Button } from './styles';

// - tradução
import { LanguageContext } from 'containers/Language';

const origin = process.env.REACT_APP_BASE_URL;

function Disciplines() {
  const [disciplines, setDisciplines] = useState([]);

  const { userLanguage } = useContext(LanguageContext);

  useEffect(() => {
    fetch(`${origin}/api/disciplines`)
      .then(async (response) => {
        const json = await response.json();
        setDisciplines(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []);

  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'Disciplines',
    },
  ];
  return (
    <>
      <PageHeader
        bannerTitle="Disciplines"
        pageTitle="Disciplines"
        breadcrumbs={breadcrumbs}
        //img={banner}
        page="disciplines"
      />
      <Container style={{ minHeight: '60vh' }}>
        <Row style={{ padding: '80px 0 80px 0' }}>
          <Col lg="3" md="12" sm="12" style={{ marginBottom: 40 }}>
            <DisciplineNav activeItem="All Disciplines" />
          </Col>

          <Col lg="9" md="12" sm="12">
            <Row>
              {disciplines.map((discipline, index) => (
                <Col lg="6" key={discipline._id} style={{ marginBottom: 60 }}>
                  <img
                    src={`${origin}${discipline.imagePath}`}
                    alt=""
                    className="img-responsive"
                    style={{ height: '250px', width: '100%' }}
                  />
                  <br />
                  <DisciplineTitle>
                    {userLanguage === 'es'
                      ? discipline.spanish.title
                      : discipline.english.title}
                  </DisciplineTitle>
                  <Divider />
                  <p style={{ fontSize: '16px' }}>
                    {userLanguage === 'es'
                      ? discipline.spanish.subheadline
                      : discipline.english.subheadline}
                  </p>
                  <br />
                  <Button
                    className="btn lesspadd btn-yellow less-round"
                    href={`/disciplines/${discipline._id}`}
                    target="_blank"
                  >
                    Read more
                  </Button>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );

  /* return (
    <>
      <PageHeader bannerTitle="Disciplines" pageTitle="Disciplines" breadcrumbs={breadcrumbs} img={banner} />
      <Container style={{ minHeight: '60vh' }}>

        <Row style={{ padding: '80px 0 80px 0' }}>

          <Col lg="3" md="12" sm="12" style={{ marginBottom: 40 }}>
            <DisciplineNav activeItem="All Disciplines" />
          </Col>

          <Col lg="9" md="12" sm="12">
            <Row>
              {disciplines.map((item, index) => (
                <Col lg="6" key={index} style={{ marginBottom: 60 }}>
                  <img src={disciplineImg} alt="" className="img-responsive" />
                  <br />
                  <DisciplineTitle>
                    {item.name}
                  </DisciplineTitle>
                  <Divider />
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit Suspendisse et justo Praesent
                    mattis
                    commodo augue Aliquam ornare augue.
                  </p>
                  <br />
                  <Button className="btn lesspadd btn-yellow less-round" href={item.url}>Read more</Button>

                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  ); */
}

export default Disciplines;
