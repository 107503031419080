import React from 'react';

// - react-bootstrap
import { Container, Col, Row } from 'react-bootstrap';

// - components
import PageHeader from 'components/PageHeader';

// - sections
import Quote from './sections/Quote';
import History from './sections/History';
import Media from './sections/Media';

import Cover from 'assets/img/aboutcopac.png';

import sideImage from 'assets/img/agua.png';

function AboutCOPAC() {
  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'About Us',
    },
  ];

  return (
    <>
      <PageHeader
        bannerTitle="aboutCOPAC"
        pageTitle="aboutCOPAC"
        breadcrumbs={breadcrumbs}
        //img={Cover}
        page={'about'}
      />
      <Container style={{ paddingBottom: 80 }}>
        <Row>
          <Col sm={12}>
            <Quote />
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={6}>
            <History />
          </Col>
          <Col sm={12} lg={6} className="mt-sm-4 mt-lg-0">
            <img src={sideImage} alt={`Banner `} className="img-responsive" />
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default AboutCOPAC;
