import React from 'react';

// -- FontAwesome
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// -- styled-components do Footer
import {
  FooterTitle,
  TitleUnderline,
  LinkList,
  LinkItem,
  SocialList,
} from '../styles';

export default function QuickLinks({ Text }) {
  return (
    <>
      <FooterTitle><Text content="quickLinks" /></FooterTitle>
      <TitleUnderline />
      <div className="clearfix" />

      <SocialList>
        <li>
          <a href="/">
            Home
          </a>
        </li>
        <li>
          <a href="/news">
            News
          </a>
          
        </li>
        <li>
          <a href="/disciplines">
            Disciplines
          </a>
        </li>
        <li>
          <a href="/events">
            Events
          </a>
        </li>
        <li>
          <a href="/about">
            Governance
          </a>
        </li>
        <li>
          <a href="/contact">
            Contact
          </a>
        </li>
      </SocialList>

      {/* <LinkList>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            About copac
          </a>
        </LinkItem>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            Discplines
          </a>
        </LinkItem>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            News
          </a>
        </LinkItem>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            Events
          </a>
        </LinkItem>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            Contact
          </a>
        </LinkItem>
      </LinkList> */}
    </>
  );
}
