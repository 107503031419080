import styled from 'styled-components';

export const SocialMediaContainer = styled.div`
  .row {
    gap: 16px;
  }

  .col, .col-5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    background-color: #f7f7f7;

    height: 150px;

    a {
      padding: 6px 8px;
      background-color: #006CB1;
      color: #fff;

      font-size: 14px;

      @media (max-width: 500px) {
      font-size: 12px;
    }
    }
  }

  .col-md-5 {
    background-color: #f7f7f7;
    height: 150px;
    padding: 0 48px;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    div {
      width: 100%;
      display: flex;
      align-items: center;

      input {
        height: 50px;
        flex: 1;
        border: none;
        outline: none;
  
        padding: 16px;
      }
  
      button {
        height: 50px;
        padding: 16px 16px;
        background-color: #006CB1;
        color: #fff;
  
        border: none;
        outline: none;
  
        font-size: 14px;
      }

    }

    @media (max-width: 500px) {
      padding: 0 15px;
    }
  }
`;
