import React, { useState, useEffect, useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Link } from 'react-router-dom';

import { useParams } from 'react-router-dom';

import PageHeader from 'components/PageHeader';

// - sections

// Styles
import {
  TabsOutlineNone,
  Title,
  PostInfo,
  NewsListContainer,
  DocumentContainer,
  NewsButton,
  NewsButtonContainer,
  ContentContainer,
} from './styles.js';

// - react-bootstrap
import { Container, Col, Row, /* Tabs, */ Tab } from 'react-bootstrap';

// - tradução
import { LanguageContext } from 'containers/Language';
// - Texto traduzido
import { Text } from 'containers/Language';

// -- FontAwesome
import { faUser, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Cover from 'assets/img/eventsitem.png';

import iconPDF from 'assets/img/iconPDF.png';

const origin = process.env.REACT_APP_BASE_URL;

function EventItem() {
  const { userLanguage } = useContext(LanguageContext);

  const [eventItem, setEventItem] = useState();

  const [discipline, setDiscipline] = useState();

  const [eventItemNews, setEventItemNews] = useState([]);

  const [medias, setMedias] = useState([]);

  const { id } = useParams();

  /* useEffect(() => {
    fetch(`${origin}/events/${id}`)
      .then(async (response) => {
        const json = await response.json();
        setEventItem(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []) */

  useEffect(() => {
    fetch(`${origin}/api/events/${id}`)
      .then(async (response) => {
        const json = await response.json();
        setEventItem(json);

        fetch(`${origin}/api/disciplines/${json.discipline}`)
          .then(async (response) => {
            const json = await response.json();
            setDiscipline(json);
          })
          .catch((error) => {
            console.log('erro', error);
          });

        fetch(`${origin}/api/events/${json._id}/news`)
          .then(async (response) => {
            const json = await response.json();
            setEventItemNews(json);
          })
          .catch((error) => {
            console.log('erro', error);
          });

        fetch(`${origin}/api/events/${json._id}/medias`)
        .then(async (response) => {
          const json = await response.json();
          setMedias(json);
          console.log('medias', json)
        })
        .catch((error) => {
          console.log('erro', error);
        });
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, [id]);

  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'Events',
    },
  ];

  function parseMarkdownToHtml(markdown) {
    // Substitui markdown por tags HTML básicas
    let html = markdown
    .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>') // Negrito
    .replace(/\*(.*?)\*/g, '<em>$1</em>') // Itálico
    .replace(/\#\#\#\s(.*?)\n/g, '<strong>$1</strong>\n') // Título de nível 3
    .replace(/\#\#\s(.*?)\n/g, '<strong>$1</strong>\n') // Título de nível 2
    .replace(/\#\s(.*?)\n/g, '<strong>$1</strong>\n') // Título de nível 1
    .replace(/\[([^\]]+)\]\((https?:\/\/[^\s)]+)\)/g, '<a href="$2">$1</a>') // Links

    //.replace(/(^|\n)- (.*?)(\n|$)/g, '$1<ul><li>$2</li></ul>$3') // Bullet points
    .replace(/<\/li>\s*<li>/g, '') // Remove ul's separadas entre os itens da lista
    .replace(/^<li>/g, '<ul><li>') // Adiciona a <ul> antes do primeiro item
    .replace(/<\/li>$/g, '</li></ul>') // Adiciona a </ul> após o último item
    
    .replace(/<ul><\/ul>/g, '') // Remove ul vazios que podem ser criados
    .replace(/^(?!<.*?>)([^\n]+)$/gm, '<p>$1</p>'); // Envolve texto normal em <p>
    //.replace(/\n/g, '<br>'); // Quebra de linha

    // Junta listas consecutivas
    //html = html.replace(/<\/ul>\s*<ul>/g, '');
  
    return html;
  }

  function parserHtml(string) {
    if (!string) {
      return '';
    }

    if(string.startsWith('<')) {
      return ReactHtmlParser(string);
    }

    // Verifica se a string contém sintaxe markdown
    const isMarkdown = /[*_#`[\]-]/.test(string);


    if (isMarkdown) {
      const markdownHtml = parseMarkdownToHtml(string);

      console.log({isMarkdown, markdownHtml})

      return ReactHtmlParser(markdownHtml);
    }

    //const paragraphs = newsItem?.english?.body?.split('\r\n');

    const paragraphs = string?.split('\r\n');

    return (
      <div>
        {paragraphs.map((paragraph, index) => (
          //<p key={index}>{paragraph}</p>
          <p key={index}>{ReactHtmlParser(paragraph)}</p>
        ))}
      </div>
    )
  }

  return (
    <>
      <PageHeader
        bannerTitle="events"
        pageTitle="events"
        breadcrumbs={breadcrumbs}
        //img={Cover}
        page="events"
      />

      {!eventItem ? (
        <Container>
          <Row className="justify-content-center py-4 my-4">
            {/* <h6>404 - Record not found</h6> */}
            <h6>{userLanguage === 'es'
                  ? "Cargando datos..."
                  : "Loading data..."}</h6>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col>
              <h3 className="my-4">
                {userLanguage === 'es'
                  ? eventItem.spanish.title
                  : eventItem.english.title}
              </h3>
              <div style={{ margin: '22px 0' }}>
                <h6>
                  {`${
                    userLanguage === 'es'
                      ? 'Periodo del evento:'
                      : 'Event period:'
                  } `}
                  {/* {`${new Date(eventItem.startDate).getDate()} `} */}

                  {`${
                    new Date(eventItem.startDate).getMonth() ===
                    new Date(eventItem.endDate).getMonth()
                      ? `${new Date(eventItem.startDate).getDate()} `
                      : `${
                          userLanguage === 'es'
                            ? `${new Date(
                                eventItem.startDate
                              ).toLocaleDateString(userLanguage, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })}`
                            : `${new Date(
                                eventItem.startDate
                              ).getDate()} ${new Date(
                                eventItem.startDate
                              ).toLocaleDateString(userLanguage, {
                                year: 'numeric',
                                month: 'long',
                              })}`
                        }`
                  } `}

                  {`${userLanguage === 'es' ? 'al' : 'to'} `}
                  {`${
                    userLanguage === 'es'
                      ? `${new Date(eventItem.endDate).toLocaleDateString(
                          userLanguage,
                          {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          }
                        )}`
                      : `${new Date(eventItem.endDate).getDate()} ${new Date(
                          eventItem.endDate
                        ).toLocaleDateString(userLanguage, {
                          year: 'numeric',
                          month: 'long',
                        })}`
                  }`}
                  {/* {`${new Date(eventItem.endDate).toLocaleDateString(userLanguage, {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}`} */}
                </h6>
                <h6>
                  {`${userLanguage === 'es' ? 'Localización:' : 'Location:'} `}
                  {`${eventItem.local}`}
                </h6>
                <h6>
                  {`${
                    userLanguage === 'es' ? 'Disciplinas:' : 'Disciplines:'
                  } `}
                  {userLanguage === 'es'
                    ? discipline?.spanish?.title
                    : discipline?.english?.title}
                </h6>
              </div>
              <TabsOutlineNone
                defaultActiveKey={1}
                id="uncontrolled-tab-example"
              >
                <Tab
                  eventKey={1}
                  title={`${userLanguage === 'es' ? 'Información' : 'Info'} `}
                >
                  <div style={{ padding: '32px 0 80px' }}>
                    {/* {userLanguage === 'es'
                      ? ReactHtmlParser(eventItem.spanish.body)
                      : ReactHtmlParser(eventItem.english.body)} */}

                    {userLanguage === 'es'
                      ? parserHtml(eventItem.spanish.body)
                      : parserHtml(eventItem.english.body)}
                  </div>
                </Tab>
                <Tab
                  eventKey={2}
                  title={`${userLanguage === 'es' ? 'Noticias' : 'News'} `}
                  /* style={{paddingBottom: '80px'}} */
                >
                  <div style={{ padding: '32px 0 80px' }}>
                    {eventItemNews.length !== 0 ? (
                      <NewsListContainer>
                        {eventItemNews.map((newsItem) => (
                          <Row style={{ marginBottom: '10px' }}>
                            <div
                              className="col-lg-4 col-md-6 col-sm12" /* style={{backgroundColor: 'Red'}} */
                            >
                              <img
                                src={`${origin}${newsItem.imagePath}`}
                                alt=""
                                className="img-responsive"
                                style={{ height: '200px', width: '100%' }}
                              />
                            </div>
                            <div
                              className="col-lg-8 col-md-6 col-sm12" /* style={{backgroundColor: 'Blue'}} */
                            >
                              <Title>
                                <Link to={`/news/${newsItem._id}`}>
                                  {userLanguage === 'es'
                                    ? newsItem.spanish.title
                                    : newsItem.english.title}
                                </Link>
                              </Title>
                              <PostInfo>
                                <span>
                                  <FontAwesomeIcon icon={faUser} />
                                  {newsItem.author}
                                </span>
                                <span>
                                  <FontAwesomeIcon icon={faCalendar} />
                                  {`${new Date(
                                    newsItem.createdAt
                                  ).toLocaleDateString(userLanguage)},
                                        ${new Date(
                                          newsItem.createdAt
                                        ).toLocaleTimeString(userLanguage)}`}
                                </span>
                              </PostInfo>
                              <p style={{ marginBottom: '10px' }}>
                                {userLanguage === 'es'
                                  ? newsItem.spanish.subheadline
                                  : newsItem.english.subheadline}
                              </p>
                            </div>
                          </Row>
                        ))}
                      </NewsListContainer>
                    ) : (
                      <div
                        className="alert alert-warning text-center"
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          margin: '0 20px',
                        }}
                      >
                        <Text content="noRecordsFound" />
                      </div>
                    )}
                  </div>
                </Tab>
                <Tab
                  eventKey={3}
                  title={`${userLanguage === 'es' ? 'Fotos' : 'Photos'} `}
                >
                  <div style={{ padding: '32px 0' }}>
                    {userLanguage === 'es' ? (
                      <a
                        href={`https://${eventItem.flickrLink}`}
                        target="_blank"
                      >
                        Click aquí para ver las fotos
                      </a>
                    ) : (
                      <a
                        href={`https://${eventItem.flickrLink}`}
                        target="_blank"
                      >
                        Click here to see the photos
                      </a>
                    )}
                  </div>
                </Tab>
                <Tab
                  eventKey={4}
                  title={`${userLanguage === 'es' ? 'Resultado' : 'Result'} `}
                >
                  <div style={{ padding: '32px 0 80px' }}>
                    {
                      (!eventItem.filePath && medias.length === 0) && (
                        <div
                        className="alert alert-warning text-center"
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          margin: '0 20px',
                        }}
                      >
                        <Text content="noRecordsFound" />
                      </div>
                      )
                    }

                    {
                      eventItem.filePath !== undefined && (
                        <DocumentContainer className="d-sm-flex align-items-center mb-2 mb-sm-0" style={{paddingBottom: 16}}>
                        <ContentContainer
                          style={{ width: '60px', marginRight: '16px' }}
                        >
                          <img
                            src={iconPDF}
                            alt=""
                            className="img-responsive"
                          />
                          <h5>
                            {userLanguage === 'es'
                              ? eventItem.spanish.title
                              : eventItem.english.title}
                          </h5>
                        </ContentContainer>

                        <NewsButtonContainer>
                          <NewsButton
                            href={`${origin}${eventItem.filePath}`}
                            target="_blank"
                          >
                            Download
                          </NewsButton>
                        </NewsButtonContainer>
                      </DocumentContainer>
                      )
                    }

                    {medias.map((media) => (
                      <DocumentContainer className="d-sm-flex align-items-center mb-2 mb-sm-0" style={{paddingTop: 16, paddingBottom: 16}}>
                        <ContentContainer
                          style={{ width: '60px', marginRight: '16px' }}
                        >
                          <img
                            src={iconPDF}
                            alt=""
                            className="img-responsive"
                          />
                          <h5>
                            {media?.title}
                          </h5>
                        </ContentContainer>

                        <NewsButtonContainer>
                          <NewsButton
                            href={`${origin}${media?.filePath}`}
                            target="_blank"
                          >
                            Download
                          </NewsButton>
                        </NewsButtonContainer>
                      </DocumentContainer>
                    ))}
                  </div>
                  {/* <div style={{ padding: '32px 0 80px' }}> 
                    {!eventItem.filePath ? (
                      <div
                        className="alert alert-warning text-center"
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          margin: '0 20px',
                        }}
                      >
                        <Text content="noRecordsFound" />
                      </div>
                    ) : (
                      <DocumentContainer className="d-sm-flex align-items-center mb-2 mb-sm-0">
                        <ContentContainer
                          style={{ width: '60px', marginRight: '16px' }}
                        >
                          <img
                            src={iconPDF}
                            alt=""
                            className="img-responsive"
                          />
                          <h5>
                            {userLanguage === 'es'
                              ? eventItem.spanish.title
                              : eventItem.english.title}
                          </h5>
                        </ContentContainer>

                        <NewsButtonContainer>
                          <NewsButton
                            href={`${origin}${eventItem.filePath}`}
                            target="_blank"
                          >
                            Download
                          </NewsButton>
                        </NewsButtonContainer>
                      </DocumentContainer>
                    )}
                  </div> */}
                </Tab>
              </TabsOutlineNone>

              {/* <Nav className='nav nav-tabs' role='tablist'>
                    <a
                      id='uncontrolled-tab-tab-info'
                      className='nav-item nav-link active'
                      href='#'
                      role='tab'
                      data-rb-event-key='info'
                      aria-controls='uncrontrolled-tab-tabpane-info'
                      aria-selected='true'
                    >
                      {userLanguage === 'es' ? 'Información' : 'Info'}
                    </a>
                    <a
                      id='uncontrolled-tab-tab-info'
                      className='nav-item nav-link'
                      href='#'
                      role='tab'
                      data-rb-event-key='news'
                      aria-controls='uncrontrolled-tab-tabpane-news'
                      aria-selected='true'
                    >
                      {userLanguage === 'es' ? 'Noticias' : 'News'}
                    </a>
                  </Nav>
                  <div className='tab-content'>
                    <div
                      id='uncontrolled-tab-tabpane-news'
                      aria-labelledby='uncontrolled-tab-tab-news'
                      role='tabpanel'
                      aria-hidden='false'
                      className='fade tab-pane active show'
                    >
                      <div style={{ padding: '25px 0' }}>
                        {(
                          userLanguage === 'es'
                            ? ReactHtmlParser(eventItem.spanish.body)
                            : ReactHtmlParser(eventItem.english.body)
                        )}
                      </div>
                    </div>
                    <div
                      id='uncontrolled-tab-tabpane-news'
                      aria-labelledby='uncontrolled-tab-tab-news'
                      role='tabpanel'
                      aria-hidden='false'
                      className='fade tab-pane show'
                    >

                    </div>
                  </div> */}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default EventItem;
