import React, { useState, useEffect, useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { useParams } from 'react-router-dom';

// - react-bootstrap
import { Container, Col, Row } from 'react-bootstrap';

// - components
import PageHeader from 'components/PageHeader';
import DisciplineNav from 'components/DisciplineNav';

// - images
import banner from 'assets/img/1920x600.png';
// import overviewImg from 'assets/img/canoe_sprint_small.jpg';
// import canoeSprintImg from 'assets/img/850x350.png';

// - styles
import { Text } from 'containers/Language';
import { Title, Divider } from './styles';

// - sections
import News from './sections/News';

import { LanguageContext } from 'containers/Language';

const origin = process.env.REACT_APP_BASE_URL;

function CanoeSprint() {
  const { id } = useParams();

  const [disciplineItem, setDisciplineItem] = useState();

  const { userLanguage } = useContext(LanguageContext);

  useEffect(() => {
    fetch(`${origin}/api/disciplines/${id}`)
      .then(async (response) => {
        const json = await response.json();
        setDisciplineItem(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, [id]);

  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'Disciplines',
      path: '/disciplines',
    },
    {
      title: 'Canoe Sprint',
    },
  ];

  return (
    <>
      <PageHeader
        bannerTitle="Canoe Sprint"
        pageTitle="Canoe Sprint"
        breadcrumbs={breadcrumbs}
        //img={banner}
        page="disciplines"
      />
      <Container style={{ minHeight: '60vh' }}>
        <Row style={{ padding: '80px 0 80px 0' }}>
          <Col lg="3" md="12" sm="12" style={{ marginBottom: 40 }}>
            {/* <DisciplineNav activeItem="Canoe Sprint" /> */}
            <DisciplineNav activeItem={id} />
          </Col>

          <Col lg="9" md="12" sm="12">
            <Row style={{ marginBottom: 50 }}>
              <Col lg="6">
                <img
                  src={`${origin}${disciplineItem?.imagePath}`}
                  alt="Overview"
                  className="img-responsive"
                />
                <br />
              </Col>

              <Col lg="6">
                {/* <Title>
                  {(
                    userLanguage === 'es'
                      ? disciplineItem?.spanish.title
                      : disciplineItem?.english.title
                  )}
                </Title>
                <Divider />
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit Suspendisse et justo Praesent mattis commodo augue Aliquam ornare augue consectetuer </p>
                <br />
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit Suspendisse et justo Praesent mattis commodo augue Aliquam ornare augue consectetuer </p> */}
                {userLanguage === 'es'
                  ? disciplineItem?.spanish.subheadline
                  : disciplineItem?.english.subheadline}
              </Col>
            </Row>

            <div className="clearfix" />

            <News Text={Text} disciplineId={id} />

            <div className="clearfix" />

            <Row>
              <Col lg="12">
                <img
                  src={`${origin}${disciplineItem?.imagePath}`}
                  alt="Canoe Sprint"
                  className="img-responsive"
                />
                <br />
                <Title>
                  {userLanguage === 'es'
                    ? disciplineItem?.spanish.title
                    : disciplineItem?.english.title}
                </Title>
                <Divider />
                {/* <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commodo augue. Aliquam ornare hendrerit augue. Cras tellus. In pulvinar lectus a est. Curabitur eget orci. Cras laoreet ligula. Etiam sit amet dolor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nullam tellus diam, volutpat a, laoreet vel, bibendum in, nibh. Donec elit lectus, pharetra quis, vulputate in, lobortis</p>
                <br />

                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commodo augue. Aliquam ornare hendrerit augue. Cras tellus. In pulvinar lectus a est. Curabitur eget orci. Cras laoreet ligula. Etiam sit amet dolor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nullam tellus diam, volutpat a, laoreet vel, bibendum in, nibh. Donec elit lectus, pharetra quis, vulputate in, lobortis</p> */}
                {userLanguage === 'es'
                  ? ReactHtmlParser(disciplineItem?.spanish.body)
                  : ReactHtmlParser(disciplineItem?.english.body)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );

  /* return (
    <>
      <PageHeader bannerTitle="Canoe Sprint" pageTitle="Canoe Sprint" breadcrumbs={breadcrumbs} img={banner} />
      <Container style={{ minHeight: '60vh' }}>

        <Row style={{ padding: '80px 0 80px 0' }}>

          <Col lg="3" md="12" sm="12" style={{ marginBottom: 40 }}>
            <DisciplineNav activeItem="Canoe Sprint" />
          </Col>

          <Col lg="9" md="12" sm="12">
            <Row style={{ marginBottom: 50 }}>
              <Col lg="6">
                <img src={overviewImg} alt="Overview" className="img-responsive" />
                <br />
              </Col>

              <Col lg="6">
                <Title>Overview</Title>
                <Divider />
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit Suspendisse et justo Praesent mattis commodo augue Aliquam ornare augue consectetuer </p>
                <br />
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit Suspendisse et justo Praesent mattis commodo augue Aliquam ornare augue consectetuer </p>
              </Col>
            </Row>

            <div className="clearfix" />

            <News Text={Text} />

            <div className="clearfix" />

            <Row>
              <Col lg="12">
                <img src={canoeSprintImg} alt="Canoe Sprint" className="img-responsive" />
                <br />
                <Title>Canoe Sprint</Title>
                <Divider />
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commodo augue. Aliquam ornare hendrerit augue. Cras tellus. In pulvinar lectus a est. Curabitur eget orci. Cras laoreet ligula. Etiam sit amet dolor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nullam tellus diam, volutpat a, laoreet vel, bibendum in, nibh. Donec elit lectus, pharetra quis, vulputate in, lobortis</p>
                <br />

                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commodo augue. Aliquam ornare hendrerit augue. Cras tellus. In pulvinar lectus a est. Curabitur eget orci. Cras laoreet ligula. Etiam sit amet dolor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nullam tellus diam, volutpat a, laoreet vel, bibendum in, nibh. Donec elit lectus, pharetra quis, vulputate in, lobortis</p>

              </Col>
            </Row>
          </Col>

        </Row>
      </Container>
    </>
  ); */
}

export default CanoeSprint;
