import React from 'react';

// -- styled-components do Footer
import { FooterTitle, TitleUnderline, SocialList } from '../styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

export default function Social() {
  return (
    <>
      <FooterTitle>Social</FooterTitle>
      <TitleUnderline />
      <div className="clearfix" />

      <SocialList>
        <li>
          <a href="https://www.facebook.com/COPACOnline/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} />
            Facebook
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/copaconline/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
            Instagram
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/@copaconline1589" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} />
            Youtube
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/internationalcanoefederation/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          LinkedIn
          </a>
          
        </li>
        <li>
          <a href="https://x.com/copaconline" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} />
            X
          </a>
        </li>
      </SocialList>
    </>
  );
}
