import React, { useState, useEffect } from 'react';

import bannerDestaque from 'assets/img/banner_home.jpg';
import { Container, Col, Row } from 'react-bootstrap';

// -- Sections
import { Text } from 'containers/Language';
import BannerCarousel from './sections/BannerCarousel';
// import NewsHighlight from './sections/NewsHighlight';
import News from './sections/News';
// import Instagram from './sections/Instagram';
// import CopacOnMedia from './sections/CopacOnMedia';
// import Newsletter from './sections/Newsletter';

import SocialMedia from './sections/SocialMedia';

import Events from './sections/Events';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import banner1 from 'assets/img/banner-1.jpeg';
import banner2 from 'assets/img/banner-2.jpeg';
import banner3 from 'assets/img/banner-3.jpeg';
// import Statutes from './sections/Statutes';
// import Federations from './sections/Federations';

// - Texto traduzido

const origin = process.env.REACT_APP_BASE_URL;

function Home() {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    fetch(`${origin}/api/banners`)
      .then(async (response) => {
        const json = await response.json();
        setBanners(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });

  }, []);

  return (
    <>
      <BannerCarousel Text={Text} />
      {/* <NewsHighlight Text={Text} /> */}
      <Container
        style={{
          display: 'flex',
          marginTop: 20,
          justifyContent: 'center',
          maxWidth: '100%',
          overflow: 'hidden',
        }}
      >
        {
          banners[0] !== undefined && (
            <Row>
              <a
                //href="https://www.canoeicf.com/news/how-watch-live-coverage-icf-events-2024"
                href={banners[0]?.url}
                target="_blank" rel="noopener noreferrer"
                style={{
                  cursor: 'pointer',
                }}
              >
                <img
                  //src={bannerDestaque}
                  src={`${origin}${banners[0]?.imagePath}`}
                  alt="destaque"
                  style={{ width: '100%', cursor: 'pointer' }}
                />
              </a>
            </Row>
          )
        }
      </Container>
      <News Text={Text} />
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 20,
          justifyContent: 'center',
          maxWidth: '100%',
          overflow: 'hidden',
          gap: 20,
        }}
      >
        <Row
          style={{
            gap: 20,
          }}
        >
          {
            banners[1] !== undefined && (
              <Col
                style={{
                  padding: 0,
                }}
              >
                <a
                  //href={banner1}
                  href={banners[1]?.url}
                  target="_blank" rel="noopener noreferrer"
                  style={{
                    cursor: 'pointer',
                    height: '10vh',
                  }}
                >
                  <img
                    //src={banner2}
                    src={`${origin}${banners[1]?.imagePath}`}
                    alt="destaque"
                    style={{ width: '100%', cursor: 'pointer' }}
                  />
                </a>
              </Col>
            )
          }
          {
            banners[2] !== undefined && (
              <Col
                style={{
                  padding: 0,
                }}
              >
                <a
                  //href="https://www.canoeicf.com/news/how-watch-live-coverage-icf-events-2024"
                  href={banners[2]?.url}
                  target="_blank" rel="noopener noreferrer"
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <img
                    //src={banner1}
                    src={`${origin}${banners[2]?.imagePath}`}
                    alt="destaque"
                    style={{ width: '100%', cursor: 'pointer' }}
                  />
                </a>
              </Col>
            )
          }
        </Row>
        {
          banners[3] !== undefined && (
            <Row>
              <a
                //href="https://www.canoeicf.com/news/how-watch-live-coverage-icf-events-2024"
                href={banners[3]?.url}
                target="_blank" rel="noopener noreferrer"
                style={{
                  cursor: 'pointer',
                }}
              >
                <img
                  //src={banner3}
                  src={`${origin}${banners[3]?.imagePath}`}
                  alt="destaque"
                  style={{ width: '100%', cursor: 'pointer' }}
                />
              </a>
            </Row>
          )
        }
      </Container>
      {/* <Instagram Text={Text} /> */}
      {/* {<CopacOnMedia Text={Text} />} */}
      {/* <Newsletter Text={Text} /> */}

      <SocialMedia Text={Text} />

      <Events Text={Text} />
      {/* <Statutes Text={Text} /> */}
      {/* <Federations Text={Text} /> */}
    </>
  );
}

export default Home;
