import React from 'react';

// -- FontAwesome
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// -- styled-components do Footer
import {
  FooterTitle,
  TitleUnderline,
  LinkList,
  LinkItem,
  SocialList,
} from '../styles';

export default function AboutCopac({ Text }) {
  return (
    <>
      <FooterTitle><Text content="aboutCOPAC" /></FooterTitle>
      <TitleUnderline />
      <div className="clearfix" />

      <SocialList>
        <li>
          <a href="/about">
            <Text content="history" />
          </a>
        </li>
        <li>
          <a href="/about/statutes">
            <Text content="statutes" />
          </a>
        </li>
        <li>
          <a href="/about/documents">
            <Text content="documents" />
          </a>
          
        </li>
        <li>
          <a href="/about/board-of-directors">
            <Text content="boardOfDirectors" />
          </a>
        </li>
        <li>
          <a href="/about/committees">
            <Text content="committees" />
          </a>
        </li>
        <li>
          <a href="/about/national-federations">
            <Text content="nationalFederations" />
          </a>
        </li>
      </SocialList>

      {/* <LinkList>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            About copac
          </a>
        </LinkItem>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            Discplines
          </a>
        </LinkItem>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            News
          </a>
        </LinkItem>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            Events
          </a>
        </LinkItem>
        <LinkItem>
          <FontAwesomeIcon icon={faAngleRight} />
          <a href="#lorem">
            Contact
          </a>
        </LinkItem>
      </LinkList> */}
    </>
  );
}
