/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useHistory, useLocation  } from 'react-router-dom';

// - react-bootstrap
import { Nav, Row } from 'react-bootstrap';

import LanguageSelector from 'components/LanguageSelector';

// - Texto traduzido
import { Text } from 'containers/Language';

// - Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

// - images
import logo from 'assets/img/logo/main-logo.png';

// - styles
import { Topbar, NavBar, NavLink, Dropdown } from './styles';

// - tradução
import { LanguageContext } from 'containers/Language';

const origin = process.env.REACT_APP_BASE_URL;

function Header() {
  const history = useHistory();

  const [isSticky, setSticky] = useState(false);
  const headerRef = useRef(null);

  const [disciplines, setDisciplines] = useState([]);

  const [pages, setPages] = useState([]);

  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  const location = useLocation();

  const handleScroll = () => {
    if (headerRef.current) {
      setSticky(
        window.pageYOffset > headerRef.current.getBoundingClientRect().top
      );
    }
  };

  useEffect(() => {
    // run once the component is loaded - componentDidMount()
    window.addEventListener('scroll', handleScroll);

    fetch(`${origin}/api/disciplines`)
      .then(async (response) => {
        const json = await response.json();
        setDisciplines(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });

    fetch(`${origin}/api/pages`)
    .then(async (response) => {
      const json = await response.json();
      setPages(json);
    })
    .catch((error) => {
      console.log('erro', error);
    });

    return () => {
      // run after the component is removed - componentWillUnmount()
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // Exemplo: obter um parâmetro chamado "lang"
    const lang = searchParams.get('lang');

    if (lang === 'es') {
      userLanguageChange('es');
    } 

    if (lang === 'en') {
      userLanguageChange('en');
    }

  }, [location]);

  return (
    <>
      <Topbar>
        <Row className="justify-content-between px-3">
          <LanguageSelector />
          <div>
            <a href="https://www.instagram.com/copaconline/"  target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a>
            <a href="https://x.com/copaconline" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} size="lg" />
            </a>
            <a href="https://www.linkedin.com/company/internationalcanoefederation/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
            </a>
          </div>
        </Row>
      </Topbar>
      <NavBar variant="light" expand="lg" sticky={isSticky} ref={headerRef}>
        <NavBar.Brand href="/" className="h-100">
          <img
            src={logo}
            className="d-inline-block align-top"
            alt="COPAC logo"
          />
        </NavBar.Brand>
        <NavBar.Toggle aria-controls="basic-NavBar-nav" />
        <NavBar.Collapse className="justify-content-end">
          <Nav>
            <NavLink href="/" active>
              <Text content="home" />
            </NavLink>
            <Dropdown className="dropdown">
              <Link
                className="dropdown-toggle nav-link"
                to="/news"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Text content="news" />
              </Link>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {/* <Link
                  className="dropdown-item"
                  to={`/news`}
                  onCLick={(e) => {
                    e.preventDefault();
                    window.location.href = '/news'; // Recarrega a página
                  }}
                >
                  {userLanguage === 'es'
                    ? 'Todas las noticias'
                    : 'All news'
                  }
                </Link>
                {disciplines.map((discipline) => (
                  <Link
                    key={discipline._id}
                    className="dropdown-item"
                    to={`/news?disciplineId=${discipline._id}`}
                    onCLick={(e) => {
                      e.preventDefault();
                      window.location.href = `/news?disciplineId=${discipline._id}`; // Recarrega a página
                    }}
                  >
                    {userLanguage === 'es'
                      ? discipline.spanish.title
                      : discipline.english.title}
                  </Link>
                ))} */}
                <button
                  className="dropdown-item"
                  onClick={() => history.push('/news', { disciplineId: null })}
                >
                  {userLanguage === 'es' 
                    ? 'Todas las noticias' 
                    : 'All news'}
                </button>
                {disciplines.map((discipline) => (
                  <button
                    key={discipline._id}
                    className="dropdown-item"
                    onClick={() => history.push('/news', { disciplineId: discipline._id })}
                  >
                    {userLanguage === 'es' 
                      ? discipline.spanish.title 
                      : discipline.english.title}
                  </button>
                ))}
              </div>
            </Dropdown>

            <Dropdown className="dropdown">
              <Link
                className="dropdown-toggle nav-link"
                to="/disciplines"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Text content="disciplines" />
              </Link>

              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {disciplines.map((discipline) => (
                  <Link
                    key={discipline._id}
                    className="dropdown-item"
                    to={`/disciplines/${discipline._id}`}
                  >
                    {/* <Text content="canoeSprint" /> */}
                    {userLanguage === 'es'
                      ? discipline.spanish.title
                      : discipline.english.title}
                  </Link>
                ))}
              </div>
              {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="canoeSprint" /></Link>
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="canoeSlalom" /></Link>
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="paracanoe" /></Link>
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="canoePolo" /></Link>
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="canoeMarathon" /></Link>
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="canoeWildwater" /></Link>
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="canoeFreestyle" /></Link>
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="canoeOceanRacing" /></Link>
              </div> */}
            </Dropdown>
            <NavLink href="/events">
              <Text content="events" />
            </NavLink>
            <Dropdown className="dropdown">
              <Link
                className="dropdown-toggle nav-link"
                to="/about"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Text content="aboutCOPAC" />
              </Link>
              {/* <Text content="governance" /> */}
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {pages.map((page) => (
                  <Link
                    key={page._id}
                    className="dropdown-item"
                    to={`/pages/${page._id}`}
                  >
                    {/* <Text content="canoeSprint" /> */}
                    {userLanguage === 'es'
                      ? page.spanish.title
                      : page.english.title}
                  </Link>
                ))}
                <Link className="dropdown-item" to="/about">
                  <Text content="history" />
                </Link>
                <Link className="dropdown-item" to="/about/statutes">
                  <Text content="statutes" />
                </Link>
                <Link className="dropdown-item" to="/about/documents">
                  <Text content="documents" />
                </Link>
                <Link className="dropdown-item" to="/about/board-of-directors">
                  <Text content="boardOfDirectors" />
                </Link>
                <Link className="dropdown-item" to="/about/committees">
                  <Text content="committees" />
                </Link>
                {/* <Link className="dropdown-item" to="#"><Text content="minutes" /></Link> */}
                <Link
                  className="dropdown-item"
                  to="/about/national-federations"
                >
                  <Text content="nationalFederations" />
                </Link>
              </div>
            </Dropdown>
            <NavLink href="/contact">
              <Text content="contact" />
            </NavLink>
            {/* <Dropdown className="dropdown">
              <Link className="dropdown-toggle nav-link" to="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <Text content="tokyo2020" />
              </Link>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <Link className="dropdown-item" to="#"><Text content="canoeSprint" /></Link>
                <Link className="dropdown-item" to="#"><Text content="canoeSlalom" /></Link>
                <Link className="dropdown-item" to="#"><Text content="paracanoe" /></Link>
              </div>
            </Dropdown> */}
          </Nav>
        </NavBar.Collapse>
      </NavBar>
    </>
  );
}

export default Header;
